import './App.css';
import React, { useCallback, useEffect, useState } from 'react';
import usePageTracking from './layout/pageTrackingHook/usePagetracking';
// import Vimeo from '@u-wave/react-vimeo';
import {
  Box,
  Container,
  AppBar,
  CircularProgress,
  Alert,
  LinearProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import WorkingOrderAndResultTable from './layout/WorkingOrderAndResultTable';
import {
  // TRANSMISSION_TEST_DATA,
} from './test-data';
import axios from 'axios';
import {
  API_SERVER_ROOT_ADDRESS,
  EVENT_REFRESH_INTERVAL,
} from './constants';
import useInterval from './useInterval';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: purple[500],
      contrastText: '#fff',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
      contrastText: '#fff',
    },
    background: {
      default: '#0a1929',
      paper: '#001e3c',
    },
    text: {
      primary: '#fff',
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
  },
});

const LiveTransmission = (props) => {
  const { identificadorVimeo, identificadorYouTube } = props;
  return (
    identificadorVimeo.length ?
      <React.Fragment>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe title="vimeo" frameBorder="0" src={`https://vimeo.com/event/${identificadorVimeo}/embed`} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}></iframe>
        </div>
      </React.Fragment>
      :
      <React.Fragment>
        <div className="video-responsive">
          <iframe src={`https://www.youtube.com/embed/${identificadorYouTube}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </React.Fragment>
  )
}

const App = () => {

  const [transmission, setTransmission] = useState({});
  // const [transmission, setTransmission] = useState(TRANSMISSION_TEST_DATA);
  const [updating, setUpdating] = useState(false);

  // Note: The hook rule is disabled as the conditional is on a per build basis, and does not change between renders
  if (process.env.NODE_ENV === 'production') {
    usePageTracking(); // eslint-disable-line react-hooks/rules-of-hooks
  }

  const getData = useCallback(() => {
    axios
      .get(`${API_SERVER_ROOT_ADDRESS}/transmissoes_ao_vivos?page=1&itemsPerPage=1`)
      .then((response) => {
        const data = response.data;
        // only update state if something has changed
        if (data["hydra:member"][0]?.evento.id !== transmission["hydra:member"]?.[0]?.evento.id
          || data["hydra:member"][0]?.categoria.id !== transmission["hydra:member"]?.[0]?.categoria.id
          || data["hydra:member"][0]?.classificatoria?.id !== transmission["hydra:member"]?.[0]?.classificatoria?.id
          || data["hydra:member"][0]?.divisaoOrdemEntrada?.id !== transmission["hydra:member"]?.[0]?.divisaoOrdemEntrada?.id
          || data["hydra:member"][0]?.identificadorVimeo !== transmission["hydra:member"]?.[0]?.identificadorVimeo) {
          setTransmission(data);
        }
      })
      .catch((error) => {
        if (!transmission.error) {
          setTransmission({
            "error": error,
          });
        }
        console.log("error", error);
      })
      .finally(() => setUpdating(false));
  }, [transmission]);

  useEffect(() => {
    setUpdating(true);
    getData();
  }, [getData]);

  useInterval(async () => {
    setUpdating(true);
    // console.log("checking for updated transmission data");
    getData();
  }, EVENT_REFRESH_INTERVAL);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className="appbar"
          position="sticky"
          color="inherit"
        >
          <Container maxWidth="md">
            {transmission["hydra:member"]?.length
              ? <LiveTransmission identificadorVimeo={transmission["hydra:member"][0].identificadorVimeo} identificadorYouTube={transmission["hydra:member"][0].identificadorYouTube} />
              : null}
            <img className="parceiros" src={`/images/parceiros/Animacao.gif`} alt="logos patrocinadores" />
          </Container>
          <LinearProgress className={updating ? "" : "hidden"} />
        </AppBar>
        <Box sx={{ my: 4 }}
          className={"data"}
        >
          {Object.keys(transmission).length !== 0
            ? !transmission.error
              ? <WorkingOrderAndResultTable
                ordemEntrada={true}
                evento={transmission["hydra:member"][0]?.evento}
                categoria={transmission["hydra:member"][0]?.categoria}
                classificatoria={transmission["hydra:member"][0]?.classificatoria}
                divisao={transmission["hydra:member"][0]?.divisaoOrdemEntrada}
                naoIncluirResultadosExtraOficiais={true}
              />
              : <Alert severity="error">{`${transmission.error}`}</Alert>
            : <CircularProgress />
          }
        </Box>
      </Box>
    </ThemeProvider >
  );
}

export default App;
