import { CircularProgress, Hidden, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import React, { useMemo, useEffect, useState, useCallback } from 'react'
import axios from 'axios';
import {
  API_SERVER_ROOT_ADDRESS,
  NUMBER_FORMAT_LOCALE,
  CURRENCY_FORMAT,
  SCORE_REFRESH_INTERVAL,
  SCROLL_DURATION,
} from '../constants';
import _ from "lodash";
import useInterval from '../useInterval';

import {
  // OE_TEST_DATA,
} from '../test-data';
import { Alert } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import Link from "@mui/material/Link";
import { scrollTo } from '../scrollTo';

const useStyles = makeStyles(theme => ({

  classHeader: {
    variant: "outlined",
  },

  nomeEvento: {
    color: "white",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
  },

  nomeCategoria: {
    color: "white",
    fontWeight: 500,
    width: "100%",
    textAlign: "center",
  },

  table: {
    width: "100%",
  },

  headerCell: {
    fontWeight: "bold",
  },

  bunchHeader: {
    fontWeight: "bold",
  },

  collapsibleDataHeader: {
    fontWeight: "bold",
  },

  cellSpacing: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.3),
    },
  },

  cellFontSizes: {
    fontSize: '1.0em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8em',
    },
  },

  iconFontSizes: {
    fontSize: '1.2em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.0em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9em',
    },
  },

  primary: {
    color: theme.palette.primary.main,
  },

  secondary: {
    color: theme.palette.secondary.main,
  },

}));


const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function setParams({ ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais }) {
  const searchParams = new URLSearchParams();
  searchParams.set("idEvento", evento.id);
  searchParams.set("idCategoria", categoria.id);
  if (classificatoria) {
    searchParams.set("idClassificatoria", classificatoria.id);
  }
  if (subCategoria) {
    searchParams.set("idSubCategoria", subCategoria.id);
  }
  if (divisao) {
    searchParams.set("idDivisao", divisao.id);
  }
  if (light !== undefined) {
    searchParams.set("light", Number(light));
  }
  searchParams.set("resultados", Number(!ordemEntrada));
  if (naoIncluirResultadosExtraOficiais !== undefined) {
    searchParams.set("naoIncluirResultadosExtraOficiais", Number(naoIncluirResultadosExtraOficiais));
  }

  return searchParams.toString();
}

const formatarOrdemEntrada = props => `${props.inscricao.ordemEntrada}${props.inscricao.complemento ? props.inscricao.complemento : ''}`;
const formatarColocacao = props => props.inscricao.colocacao ? props.inscricao.colocacao + 'º' : '-';
const mostrarConjunto = results => results.inscricoes?.reduce((a, b) => a || b.conjunto?.length || (Array.isArray(b) && b.reduce((c, d) => c || d.conjunto?.length, false)), false);
const mostrarSubCategorias = results => results.inscricoes?.reduce((a, b) => a || b.subCategorias !== undefined || (Array.isArray(b) && b.reduce((c, d) => c || d.subCategorias !== undefined, false)), false);
const formatarNomeCompetidor = props => `${props.inscricao.competidor.nome}${(undefined === props.light && props.inscricao.light) ? '*' : ''}`;
const formatarNotaAnterior = props => props.inscricao.naoCompareceuAnterior ? 'N/C' : new Intl.NumberFormat(NUMBER_FORMAT_LOCALE).format(props.inscricao.notaAnterior);
const formatarNota = props => props.inscricao?.naoCompareceu ? 'N/C' : props.inscricao.nota !== null ? new Intl.NumberFormat(NUMBER_FORMAT_LOCALE).format(props.inscricao.nota) : null;
const formatarNotaFinal = props => props.inscricao.notaFinal !== undefined && props.inscricao.notaFinal !== null ? new Intl.NumberFormat(NUMBER_FORMAT_LOCALE).format(props.inscricao.notaFinal) : '';
const mostrarPremiacao = results => results.inscricoes?.reduce((a, b) => a || b.premiacao?.length, false);
const formatarPremiacao = props => props.inscricao.premiacao?.length ? new Intl.NumberFormat(NUMBER_FORMAT_LOCALE, { style: 'currency', currency: CURRENCY_FORMAT }).format(props.inscricao.premiacao.reduce((a, b) => a + b.valor, 0)) : ''
const mostrarNotaTotal = results => results.inscricoes?.reduce((a, b) => a || b.notaAnterior !== undefined || b.naoCompareceuAnterior !== undefined || (Array.isArray(b) && b.reduce((c, d) => c || d.notaAnterior !== undefined || d.naoCompareceuAnterior !== undefined, false)), false);
const mostrarNota = results => results.inscricoes?.reduce((a, b) => a || b.nota !== undefined || b.naoCompareceu !== undefined || (Array.isArray(b) && b.reduce((c, d) => c || d.nota !== undefined || d.naoCompareceu !== undefined, false)), false) && !results.inscricoes?.reduce((a, b) => a || b.notaAnterior !== undefined || b.naoCompareceuAnterior !== undefined || (Array.isArray(b) && b.reduce((c, d) => c || d.notaAnterior !== undefined || d.naoCompareceuAnterior !== undefined, false)), false);
const formatarNomeProprietario = props => props.inscricao.proprietario.id ? `${props.inscricao.proprietario.nome}${undefined !== props.inscricao.proprietario2 ? ' / ' + props.inscricao.proprietario2.nome : ''}` : '';
const formatarNomeCidade = props => props.inscricao.proprietario.id ? `${props.inscricao.proprietario.cidade}${props.inscricao.proprietario.cidade ? ', ' + props.inscricao.proprietario.estado : props.inscricao.proprietario.estado}${(undefined !== props.inscricao.proprietario2) && (props.inscricao.proprietario2.cidade !== props.inscricao.proprietario.cidade) ? ' / ' + props.inscricao.proprietario2.cidade + ', ' + props.inscricao.proprietario2.estado : ''}` : '';
const mostrarRepresenta = results => results.inscricoes?.reduce((a, b) => a || b.representa?.id || (Array.isArray(b) && b.reduce((c, d) => c || d.representa?.id, false)), false);
const mostrarCidade = results => !mostrarRepresenta(results); // don't show city name if we are showing the affiliate they are representing

const columns = [
  {
    id: 'ordemEntrada',
    label: 'OE',
    minWidth: '5%',
    align: 'center',
    function: formatarOrdemEntrada,
    mostrar: {
      ordemEntrada: true,
      resultados: false,
    },
  },
  {
    id: 'colocacao',
    label: 'Col.',
    minWidth: '5%',
    align: 'center',
    function: formatarColocacao,
    mostrar: {
      ordemEntrada: false,
      resultados: true,
    },
  },
  {
    id: 'conjunto',
    property: 'conjunto',
    label: 'Cj.',
    minWidth: '5%',
    align: 'center',
    mostrar: {
      function: mostrarConjunto,
    },
  },
  {
    id: 'subCategorias',
    property: 'subCategorias',
    label: 'Níveis',
    minWidth: '5%',
    align: 'center',
    mostrar: {
      function: mostrarSubCategorias,
    },
  },
  {
    id: 'animal',
    property: 'animal.nome',
    // link: '/animais/',
    // linkId: 'animal.id',
    semLink: 'animal.aConfirmar',
    label: 'Animal',
    minWidth: 'auto',
    align: 'left',
    className: "secondary",
  },
  {
    id: 'competidor',
    property: 'competidor.nome',
    // link: '/competidores/',
    // linkId: 'competidor.id',
    semLink: 'competidor.aConfirmar',
    label: 'Competidor',
    minWidth: 'auto',
    function: formatarNomeCompetidor,
    align: 'left',
  },
  {
    id: 'proprietario',
    property: 'proprietario.nome',
    label: 'Proprietário',
    minWidth: 'auto',
    function: formatarNomeProprietario,
    align: 'left',
    className: "primary",
    hidden: {
      xsDown: true,
    },
  },
  {
    id: 'cidade',
    property: 'proprietario.cidade',
    label: 'Cidade, UF',
    minWidth: 'auto',
    function: formatarNomeCidade,
    align: 'left',
    mostrar: {
      function: mostrarCidade,
    },
    hidden: {
      mdDown: true,
    },
  },
  {
    id: 'representa',
    property: 'representa.lugar',
    label: 'Representa',
    minWidth: 'auto',
    align: 'left',
    mostrar: {
      function: mostrarRepresenta,
    },
    hidden: {
      mdDown: true,
    },
  },
  {
    id: 'notaAnterior',
    label: 'Nota 1',
    minWidth: 'auto',
    align: 'center',
    function: formatarNotaAnterior,
    mostrar: {
      function: mostrarNotaTotal,
    },
  },
  {
    id: 'notaAtual',
    label: 'Nota 2',
    minWidth: 'auto',
    align: 'center',
    function: formatarNota,
    mostrar: {
      function: mostrarNotaTotal,
    },
  },
  {
    id: 'notaFinal',
    label: 'Final',
    minWidth: 'auto',
    align: 'center',
    function: formatarNotaFinal,
    mostrar: {
      function: mostrarNotaTotal,
    },
  },
  {
    id: 'nota',
    label: 'Nota',
    minWidth: 'auto',
    align: 'center',
    function: formatarNota,
    mostrar: {
      function: mostrarNota,
    },
  },
  {
    id: 'premiacao',
    label: 'Premiação',
    minWidth: 'auto',
    align: 'right',
    function: formatarPremiacao,
    mostrar: {
      function: mostrarPremiacao,
    }
  },

];

const findLastOrdemEntradaWithScore = (bateria) => {
  let ordemEntrada = null;
  bateria.forEach(element => {
    if (Array.isArray(element)) {
      let lastOrdemDeEntradaFromBunch = findLastOrdemEntradaWithScore(element);
      if (lastOrdemDeEntradaFromBunch) {
        ordemEntrada = lastOrdemDeEntradaFromBunch;
      }
      return;
    }
    if (element.nota !== null || element.naoCompareceu !== false) {
      ordemEntrada = element.ordemEntrada;
    }
  });
  return ordemEntrada;
}

const findLastScore = (results) => {
  if (!results || !results.inscricoes?.length) return null;
  if ((results.inscricoes[0]?.nota === null
    && results.inscricoes[0]?.naoCompareceu === false)
    || (results.inscricoes[0]?.[0]?.nota === null
      && results.inscricoes[0]?.[0]?.naoCompareceu === false)
    || (results.inscricoes[0]?.nota === undefined
      && results.inscricoes[0]?.naoCompareceu === undefined
      && results.inscricoes[0]?.[0]?.nota === undefined
      && results.inscricoes[0]?.[0]?.naoCompareceu === undefined)
  ) {
    // Scroll to the event title if no scores are available 
    // (ie. We just started a new class, or it isn't marked as in progress)
    setTimeout(() => {
      window.scrollTo({ top: -1000, behavior: 'smooth' });
      return null;
    }, 700);
    return null;
  }
  let oe = findLastOrdemEntradaWithScore(results.inscricoes);
  if (oe) {
    setTimeout(() => {
      let e = document.getElementById(oe);
      if (!e) return null;
      // Scroll to the last score found
      scrollTo({ id: oe, ref: null, duration: SCROLL_DURATION });
      return null;
    }, 700);
  }
}

// Note: An id is included in each entry row, so we can use it as a reference for scrolling
const Inscricao = (props) => {
  const { inscricao, columnList } = props;
  const classes = useStyles();

  return (
    <>
      <StyledTableRow tabIndex={-1} key={inscricao.ordemEntrada} id={inscricao.ordemEntrada}>
        {columns.map((column) => {
          const columnData = column.function ? column.function(props) : _.get(inscricao, column.property);
          return (
            columnList.includes(column.id) ?
              <Hidden key={column.id} {...column.hidden}>
                <TableCell align={column.align} className={clsx(classes.cellSpacing, classes.cellFontSizes, column.className ? classes[column.className] : null)}>
                  {column.link && (column.semLink && !_.get(inscricao, column.semLink)) ?
                    <Link href={column.link + _.get(inscricao, column.linkId)} color="secondary">{columnData}</Link>
                    : columnData
                  }
                </TableCell>
              </Hidden>
              : null
          );
        })}
      </StyledTableRow>
    </>
  )
};

export default function WorkingOrderAndResultTable(props) {
  const { ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais } = props;

  const classes = useStyles();

  // const [results, setResults] = useState(OE_TEST_DATA);
  const [results, setResults] = useState({});
  const [lastScoreUpdated, setLastScoreUpdated] = useState();

  const getData = useCallback(
    (ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais) => {
      axios
        .get(`${API_SERVER_ROOT_ADDRESS}/ordementrada?${setParams({ ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais })}`)
        .then((response) => {
          const data = response.data;
          setResults(data);
          findLastScore(data);
        })
        .catch((error) => {
          setResults({
            "error": error,
          });
          console.log("error", error);
        });
    },
    [],
  );

  const getLastUpdatedScoreTime = () => {
    axios
      .get(`${API_SERVER_ROOT_ADDRESS}/lastscoreupdated`)
      .then((response) => {
        const data = response.data;
        if (data.updatedAt !== lastScoreUpdated) {
          setLastScoreUpdated(data.updatedAt);
          // console.log(data.updatedAt);
          getData(ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais);
        }
      })
      .catch((error) => {
        setResults({
          "error": error,
        });
        console.log("error", error);
      });
  };

  // Puxar os dados da API quando monta o component, ou se mudar o id do evento
  useEffect(() => {
    if (evento && categoria) {
      getData(ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais);
    }
  }, [getData, ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais]);

  const columnList = useMemo(() => columns.reduce((columnList, column) => (column.mostrar === undefined || column.mostrar.ordemEntrada === ordemEntrada || column.mostrar.resultados === !ordemEntrada || (column.mostrar?.function && column.mostrar.function(results))) ? [...columnList, column.id] : columnList, []), [ordemEntrada, results]);

  useInterval(async () => {
    // console.log("checking for updated score data");
    getLastUpdatedScoreTime();
  }, SCORE_REFRESH_INTERVAL);

  // console.log(columnList);
  // console.log(results);


  return (
    <React.Fragment>
      <Typography variant="h5" className={classes.nomeEvento}>{`${evento.nome}`}</Typography>
      <Typography variant="h5" className={classes.nomeCategoria}>{`${categoria.nome}${classificatoria ? ' - ' + classificatoria.nome : ''}${light ? ' Light' : ''}${subCategoria ? ' - Nível ' + subCategoria.nome : ''}${divisao ? ` (${divisao.titulo})` : ''}`}</Typography>
      {results.inscricoes
        ? results.inscricoes.length > 0
          ? <>
            <Paper className={classes.root}>
              <TableContainer>
                <Table stickyHeader aria-label="data table" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => columnList.includes(column.id) ?
                        <Hidden key={column.id} {...column.hidden}>
                          <TableCell
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            className={clsx(classes.headerCell, classes.cellFontSizes, classes.cellSpacing)}
                          >
                            {column.label}
                          </TableCell>
                        </Hidden>
                        : null
                      )}
                    </TableRow>
                  </TableHead>


                  <TableBody>
                    {ordemEntrada
                      ? results.inscricoes.map((bateria, bunchNumber) =>
                        <React.Fragment key={bunchNumber}>
                          <TableRow>
                            <TableCell colSpan={15} className={clsx(classes.cellSpacing, classes.cellFontSizes, classes.bunchHeader)}>{`${bunchNumber + 1}ª bateria`}</TableCell>
                          </TableRow>
                          {Array.isArray(bateria) ? bateria.map(inscricao => <Inscricao key={`${inscricao.animal.id}-${inscricao.competidor.id} `} inscricao={inscricao} columnList={columnList} light={light} />
                          )
                            : /* Something went wrong */
                            <Alert severity="error">Ordem de entrada não localizada</Alert>}
                        </React.Fragment>
                      )
                      : results.inscricoes.map(inscricao => <Inscricao key={`${inscricao.animal.id}-${inscricao.competidor.id} `} inscricao={inscricao} columnList={columnList} light={light} />
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
          : "A ordem de entrada desta categoria não está disponível ainda."
        : results.error
          ? <Alert severity="error">{`${results.error} `}</Alert>
          : <CircularProgress />
      }
    </React.Fragment>
  )
}
