// API
// const API_SERVER_ROOT_ADDRESS = "https://api-ancr.org.br"; // AWS EC2 dev server
const API_SERVER_ROOT_ADDRESS = "https://api.ancr.org.br"; // AWS EC2 production server

// Dados SEO
const HELMET_CONTEXT = {
  title: "ANCR - Associação Nacional do Cavalo de Rédeas",
};

const NUMBER_FORMAT_LOCALE = 'pt-BR';
const CURRENCY_FORMAT = 'BRL';

const NOME_ASSOCIACAO = "Associação Nacional do Cavalo de Rédeas";

const EVENT_REFRESH_INTERVAL = 30000;
const SCORE_REFRESH_INTERVAL = 15000;
const SCROLL_DURATION = 1000;

// analytics
const GA4_PROPERTY_ID = "4268334844";
const GA4_MEASUREMENT_ID = "G-P4X6R0LQN8";

export {
  API_SERVER_ROOT_ADDRESS,
  HELMET_CONTEXT,
  NUMBER_FORMAT_LOCALE,
  CURRENCY_FORMAT,
  NOME_ASSOCIACAO,
  EVENT_REFRESH_INTERVAL,
  SCORE_REFRESH_INTERVAL,
  SCROLL_DURATION,
  GA4_PROPERTY_ID,
  GA4_MEASUREMENT_ID,
};